import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "../App";
import About from "./About/About";

import NotFound from "./NotFound";
import "../css/index.css";
import Portfolio from "./Portfolio/Portfolio";
import {
  NavItem,
  Container,
  WelcomeMessageContainer,
  WelcomeMessage,
  ExternalLinkContainer,
} from "./styles";
import RHFDenver from "./projects/RHFDenver/RHFDenver";

const Router = () => {
  return (
    <Container>
      <BrowserRouter>
        <WelcomeMessageContainer>
          <WelcomeMessage>Austin Burke</WelcomeMessage>
          <ExternalLinkContainer>
            <NavItem to="/">Software</NavItem>
            <NavItem to="/photography">Photography</NavItem>
          </ExternalLinkContainer>
        </WelcomeMessageContainer>
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/photography" component={Portfolio} />
          <Route exact path="/about" component={About} />
          <Route exact path="/rhf-denver" component={RHFDenver} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Container>
  );
};

export default Router;
