const largeScreenBreakpoint = 1577;
const largeScreen = `@media all and (max-width: ${largeScreenBreakpoint}px)`;

const mediumLargeScreenBreakpoint = 1350;
const mediumLargeScreen = `@media all and (max-width: ${mediumLargeScreenBreakpoint}px)`;

const mediumScreenBreakpoint = 1100;
const mediumScreen = `@media all and (max-width: ${mediumScreenBreakpoint}px)`;

const smallScreenBreakpoint = 920;
const smallScreen = `@media all and (max-width: ${smallScreenBreakpoint}px)`;

const mobileScreenBreakpoint = 715;
const mobileScreen = `@media all and (max-width: ${mobileScreenBreakpoint}px)`;

const reallySmallScreenBreakpoint = 350;
const reallySmallScreen = `@media all and (max-width: ${reallySmallScreenBreakpoint}px)`;

const reallySmallHeightBreakpoint = 570;
const reallySmallHeight = `@media all and (max-height: ${reallySmallHeightBreakpoint}px)`;

const navigationButtonLargeBreakpoint = 1516;
const navigationButtonLarge = `@media all and (max-width: ${navigationButtonLargeBreakpoint}px)`;

export {
  largeScreen,
  mediumLargeScreen,
  mediumScreen,
  smallScreen,
  mobileScreen,
  reallySmallScreen,
  reallySmallHeight,
  navigationButtonLarge,
};
