import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { mobileScreen } from "../utils/breakpoints";

const Nav = styled.div`
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 3%;
  display: flex;
  flex-direction: row;
  gap: 5%;
  color: #28777a;
`;

const NavItem = styled(NavLink)`
  color: #c74747;
  text-decoration: none;
  outline: 0;
  display: flex;
  flex-direction: row;

  &:hover {
    color: #c74747;
  }
`;

const NavItemWithOptions = styled(NavLink)`
  color: #28777a;
  text-decoration: none;
  color: inherit;
  outline: 0;
  display: flex;
  flex-direction: row;

  &:hover {
    text-decoration: none;
    color: black;
  }
`;

const NavItemWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Container = styled.div`
  height: 100%;
`;

const WelcomeMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 4%;
  width: 100%;
`;

const WelcomeMessage = styled.h1`
  font-size: 38px;
  display: flex;
  justify-content: center;
  color: #444546;

  ${mobileScreen} {
    font-size: 32px;
    width: 100%;
  }
`;

const ExternalLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

export {
  Nav,
  NavItem,
  Container,
  NavItemWithOptions,
  NavItemWrapper,
  WelcomeMessageContainer,
  WelcomeMessage,
  ExternalLinkContainer,
};
