import styled from "styled-components";

const Column = styled.div`
  flex-direction: column;
  width: 33.3333%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PortfolioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  padding: 40px;
`;

const Photo = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 17px;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export { Column, PortfolioWrapper, Photo };
