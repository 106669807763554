import React from "react";

import {
  Container,
  AboutMeContainer,
  AboutMeText,
  ExternalLink,
  Dinkus,
  NavItem,
} from "./styles";

const Home = () => {
  return (
    <Container>
      <AboutMeContainer>
        <AboutMeText>
          I use{" "}
          <ExternalLink
            href="https://github.com/aburk3"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </ExternalLink>{" "}
          as my primary code repository.
        </AboutMeText>
        <AboutMeText>
          I write mostly React, TypeScript, Python, and Django.
        </AboutMeText>
        <Dinkus>***</Dinkus>
        <AboutMeText>
          You can view my work experience on{" "}
          <ExternalLink
            href="https://www.linkedin.com/in/austin-burke/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </ExternalLink>
          .
        </AboutMeText>
        <Dinkus>***</Dinkus>
        <AboutMeText>
          I post my photography on{" "}
          <ExternalLink
            href="https://www.linkedin.com/in/austin-burke/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </ExternalLink>
          , but you can find some of it{" "}
          <NavItem to="/photography">here</NavItem>.
        </AboutMeText>
      </AboutMeContainer>
    </Container>
  );
};

export default Home;
