import styled from "styled-components";
import { mobileScreen } from "../../utils/breakpoints";
import { NavLink } from "react-router-dom";

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ExternalLink = styled.a`
  color: #c74747;

  &:hover {
    color: #c74747;
  }
`;

const AboutMeContainer = styled.div`
  flex-direction: column;
  margin-top: 2%;
  display: flex;
  justify-content: center;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #28777a;
  padding: 25px 15px;

  ${mobileScreen} {
    margin-top: 8%;
    width: 80%;
  }
`;

const AboutMeText = styled.p`
  font-size: 16px;
  width: 100%;
  margin-bottom: 0;
`;

const Dinkus = styled.div`
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #444546;
`;

const NavItem = styled(NavLink)`
  color: #c74747;
  text-decoration: none;
  outline: 0;

  &:hover {
    color: #c74747;
  }
`;

export {
  Container,
  ExternalLink,
  AboutMeContainer,
  AboutMeText,
  Dinkus,
  NavItem,
};
