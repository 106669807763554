import React from "react";
import { AWS_BUCKET_BASE_URL } from "../../../utils/constants";
import { Column, Photo, PortfolioWrapper } from "../../../css/styles";

const columnOneData = [
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-7.jpg`,
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-2.jpg`,
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-3.jpg`,
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-4.jpg`,
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-6.jpg`,
];
const columnTwoData = [
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-1.jpg`,
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-8.jpg`,
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-9.jpg`,
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-5.jpg`,
];
const columnThreeData = [
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-13.jpg`,
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-10.jpg`,
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-11.jpg`,
  `${AWS_BUCKET_BASE_URL}jesse-shoot/jesse-shoot-12.jpg`,
];

const RHFDenver = () => {
  return (
    <PortfolioWrapper>
      <Column>
        {columnOneData.map((photo, index) => (
          <Photo key={index} src={photo} alt="portfolio" />
        ))}
      </Column>
      <Column>
        {columnTwoData.map((photo, index) => (
          <Photo key={index} src={photo} alt="portfolio" />
        ))}
      </Column>
      <Column>
        {columnThreeData.map((photo, index) => (
          <Photo key={index} src={photo} alt="portfolio" />
        ))}
      </Column>
    </PortfolioWrapper>
  );
};

export default RHFDenver;
