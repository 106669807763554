import React from "react";

const NotFound = () => (
  // Create styled components
  <div>
    <h1>I'm sorry! This page was not found.</h1>
  </div>
);

export default NotFound;
