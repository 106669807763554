import React from "react";
import { Container } from "react-bootstrap";
import "./About.css";

function About() {
  return (
    <Container>
      {/* Refactor to styled components */}
      <h1 className="AboutHeading">About</h1>
      <div className="aboutContainer">
        <p className="border-paragraph">
          I'm a 29 year-old software engineer. I majored in Philosophy in
          college and began working as a litigation paralegal after graduating
          in 2017. During my time as a paralegal I began programming to solve
          small problems at work, and programming for fun at home.
        </p>
        <p className="border-paragraph">
          I currently work as a software engineer utilizing a variety of
          languages and frameworks such as: Python/Django, React, React, Ruby on
          Rails, HTML, and CSS amongst others.
        </p>
        <p className="border-paragraph">
          When I'm not programming you can find me playing chess, running, or
          exploring the outdoors.
        </p>
      </div>

      {/* Cleanup */}
      <ul className="hobbyList">
        <li>
          <i className="fas fa-chess fa-6x" />
        </li>
        <li>
          <i className="fas fa-running fa-6x" />
        </li>
        <li>
          <i className="fas fa-hiking fa-6x" />
        </li>
      </ul>
    </Container>
  );
}

export default About;
