import React from "react";
import { AWS_BUCKET_BASE_URL } from "../../utils/constants";
import { Column, Photo, PortfolioWrapper } from "../../css/styles";

const columnOneData = [
  `${AWS_BUCKET_BASE_URL}cost-rica-trees.jpg`,
  `${AWS_BUCKET_BASE_URL}mt-yale-backpack.jpg`,
  `${AWS_BUCKET_BASE_URL}tetons/teton-mountains.jpg`,
  `${AWS_BUCKET_BASE_URL}ocean-costa-rica.jpg`,
  `${AWS_BUCKET_BASE_URL}mt-yale-ag.jpg`,
  `${AWS_BUCKET_BASE_URL}tetons/teton-mountains-5252.jpg`,
  `${AWS_BUCKET_BASE_URL}kelly-1.jpg`,
  `${AWS_BUCKET_BASE_URL}copper-road.jpg`,
  `${AWS_BUCKET_BASE_URL}cameron-pass-1.jpg`,
  `${AWS_BUCKET_BASE_URL}copper-mountains-1.jpg`,
];
const columnTwoData = [
  `${AWS_BUCKET_BASE_URL}10-16-crested-butte-lake-2723.jpg`,
  `${AWS_BUCKET_BASE_URL}campsite-shenan.jpg`,
  `${AWS_BUCKET_BASE_URL}mt-yale-ag-2.jpg`,
  `${AWS_BUCKET_BASE_URL}tetons/teton-mountains-5253.jpg`,
  `${AWS_BUCKET_BASE_URL}tetons/teton-lake-jenny.jpg`,
  `${AWS_BUCKET_BASE_URL}tetons/tetons-3.jpg`,
  `${AWS_BUCKET_BASE_URL}tetons/tetons-1-2.jpg`,
  `${AWS_BUCKET_BASE_URL}RMNP-1.jpg`,
  `${AWS_BUCKET_BASE_URL}RMNP-2.jpg`,
  `${AWS_BUCKET_BASE_URL}cameron-pass-2.jpg`,
];
const columnThreeData = [
  `${AWS_BUCKET_BASE_URL}costa-rica-plant.jpg`,
  `${AWS_BUCKET_BASE_URL}lake-agnes-bree.jpg`,
  `${AWS_BUCKET_BASE_URL}campsite-leg.jpg`,
  `${AWS_BUCKET_BASE_URL}lake-agnes-island.jpg`,
  `${AWS_BUCKET_BASE_URL}tetons/tetons-2.jpg`,
  `${AWS_BUCKET_BASE_URL}tetons/teton-mountains-lake-jenny-5156.jpg`,
  `${AWS_BUCKET_BASE_URL}mt-yale-bw.jpg`,
  `${AWS_BUCKET_BASE_URL}RMNP-3.jpg`,
  `${AWS_BUCKET_BASE_URL}RMNP-dylan-1.jpg`,
  `${AWS_BUCKET_BASE_URL}drive.jpg`,
];

const Portfolio = () => {
  return (
    <PortfolioWrapper>
      <Column>
        {columnOneData.map((photo, index) => (
          <Photo key={index} src={photo} alt="portfolio" />
        ))}
      </Column>
      <Column>
        {columnTwoData.map((photo, index) => (
          <Photo key={index} src={photo} alt="portfolio" />
        ))}
      </Column>
      <Column>
        {columnThreeData.map((photo, index) => (
          <Photo key={index} src={photo} alt="portfolio" />
        ))}
      </Column>
    </PortfolioWrapper>
  );
};

export default Portfolio;
